<template>
	<div class="flex f-ai-c f-d-c">
		<div class="steps">
			<el-steps :active="active">
				<el-step title="待支付" icon="el-icon-s-order"></el-step>
				<el-step title="付款成功待发货" icon="el-icon-bank-card"></el-step>
				<el-step title="待收货" icon="el-icon-alarm-clock"></el-step>
				<el-step title="完成" icon="el-icon-check"></el-step>
			</el-steps>
		</div>
		<div class="order-container">
			<div class="order-status flex f-ai-c">
				<span style="margin-right: 8px;"> 订单号：{{prescription.orderId}}</span>
				<i class="el-icon-s-order"></i>
				<span>
					订单状态：
					{{
						prescription.status === 1 ? '待支付' :
						prescription.status === 5 ? '待发货' :
						prescription.status === 2 ? '待收货' :
						prescription.status === 3 ? '已完成' :
						prescription.status === 4 ? '已取消' :
						prescription.status === 6 ? '已退款' : ''
					}}
				</span>
			</div>
			<div class="order-status">
				<div class="order-steps-height" v-if="!expressList.length">暂无配送信息</div>
				<div class="express-list" v-else>
					<div class="express-item" v-for="(item, index) in expressList" :key="index">
						<div class="express-item-title">
							<i />
							<span>{{item.status}}</span>
						</div>
						<div class="express-item-child" v-for="(ele, i) in item.list" :key="i">
							<i />
							<div>
								<!-- <span>{{ele.status}}</span> -->
								<span>{{ele.time}}</span>
								<span>{{ele.context}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="order-status">
				<el-row>
					<el-col :span="8">
						<h1>收货人信息</h1>
						<div v-if="deliveryAddress">
							<div>收货人：{{deliveryAddress.name}}</div>
							<div>地址：{{(deliveryAddress.provenceName || '') + (deliveryAddress.cityName || '') + (deliveryAddress.countyName || '') + (deliveryAddress.address || '')}}</div>
							<div>手机号：{{deliveryAddress.mobilePhone}}</div>
						</div>
					</el-col>
					<el-col :span="7" style="margin: 0 10px 0 30px;" >
						<h1>配送方式</h1>
						<div v-if="appointment.logisticsOrderNo">
							<div>送货方式：{{appointment.logisticsName}}</div>
							<div v-if="appointment.logisticsFee">运费：{{appointment.logisticsFee}}</div>
							<div>运单号：{{appointment.logisticsOrderNo}}</div>
							<div>物流账户：暂无</div>
						</div>
						<div v-else>暂无</div>
					</el-col>
					<el-col :span="8">
						<h1>付款信息</h1>
						<div>付款方式：{{prescription.type === 'WECHAT' ? '微信' : ''}}</div>
						<div>付款时间：{{prescription.createdAt}}</div>
						<div>药品金额：￥{{prescription.totalFee || 0}}</div>
						<div>运费金额：￥{{appointment.logisticsFee || 0}}</div>
						<div>应支付金额：￥{{(prescription.totalFee || 0) + Number(appointment.logisticsFee || 0)}}</div>
					</el-col>
				</el-row>
			</div>
			<div class="order-status">
				<div class="drugs-list">
					<el-row>
						<el-col :span="11">
							<h1>处方信息</h1>
						</el-col>
					</el-row>
					<!-- <el-row class="row bold">
						<el-col :span="9">
							<span>药房</span>
						</el-col>
					</el-row> -->
					<el-row class="row">
						<el-col :span="5">
							<span class="small">开单科室：{{doctorInfo.deptName}}</span>
						</el-col>
						<el-col :span="4">
							<span class="small">开单医生：{{doctorInfo.name}}</span>
						</el-col>
					</el-row>
					<el-row class="row bold">
						<el-col :span="3">
							<span>姓名：{{patientInfo.name}}</span>
						</el-col>
						<el-col :span="3">
							<span>性别：{{patientInfo.gender === 1 ? '男' : '女'}}</span>
						</el-col>
						<el-col :span="3">
							<span>年龄：{{patientInfo.age}}岁</span>
						</el-col>
					</el-row>
					<el-row class="row">
						<el-col :span="5">
							<span class="small">处方号：{{prescription.orderId}}</span>
						</el-col>
						<el-col :span="9">
							<span class="small">处方类型：{{prescription.dosageForm || '无'}}</span>
						</el-col>
					</el-row>
					<el-row class="row">
						<el-col :span="9">
							<span class="small">
								<span>诊断：</span>
								<div>
									<div>1.中医诊断：{{prescription.zyzd}}</div>
									<div style="margin-top: 3px">2.中医辨证：{{prescription.zybz}}</div>
								</div>
							</span>
						</el-col>
					</el-row>
					<el-row class="row">
						<el-col :span="9">
							<span class="small">
								<span>医嘱：</span>
								<div>
									<div>{{prescription.yz}}</div>
								</div>
							</span>
						</el-col>
					</el-row>
					<el-row class="row">
						<el-col :span="9">
							<span class="line" />
						</el-col>
					</el-row>
					<el-row class="row large largex">
						<el-col :span="5"><span>名称</span></el-col>
						<el-col :span="4"><span>单剂数量</span></el-col>
					</el-row>
					<el-row class="row large" v-for="(item, index) in prescription._rp" :key="index">
						<el-col :span="5"><span>{{item.name}}</span></el-col>
						<el-col :span="4"><span>{{item.count}}{{item.unit}}</span></el-col>
					</el-row>
					<el-row class="row">
						<el-col :span="9">
							<span class="line" />
						</el-col>
					</el-row>
				</div>
				<el-row class="row large">
					<el-col :span="9">
						<div>共{{prescription.jiliang}}剂</div>
					</el-col>
				</el-row>
				<el-row class="row normal">
					<el-col :span="4">
						<span>用法：{{prescription.fyff}}</span>
					</el-col>
					<el-col :span="5">
						<span>每日{{prescription.jlPerDay}}剂，1剂分{{prescription.times}}次服用</span>
					</el-col>
				</el-row>
				<el-row class="row normal">
					<el-col :span="2">
						<span>诊费：￥{{prescription.outpatientCare}}</span>
					</el-col>
					<el-col :span="3">
						<span>药品金额：￥{{prescription.medicinePrice}}</span>
					</el-col>
					<el-col :span="2">
						<span>加工费用：￥{{prescription.processingFee}}</span>
					</el-col>
					<el-col :span="2">
						<span>处方管理费：￥{{prescription.mntCosts}}</span>
					</el-col>
					<el-col :span="2">
						<span>运费：￥{{prescription.logisticsFee}}</span>
					</el-col>
					<el-col :span="3">
						<span>合计金额：￥{{prescription.totalFee}}</span>
					</el-col>
				</el-row>
				<el-row class="row normal">
					<el-col :span="3">
						<span>备注：{{prescription.remarks || '无'}}</span>
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data () {
		return {
			form: {},
			detailId: '',
			steps: ['待支付', '代发货', '待收货', '完成'],
			activeMap: {
				1: 1,
				5: 2,
				2: 3,
				3: 4
			},
			active: 1,
			appointment: {},
			deliveryAddress: {},
			prescription: {},
			patientInfo: {},
			doctorInfo: {},
			expressList: []
		};
	},
	created () {
		this.detailId = this.$route.query.id;
		this.getData();
	},
	methods: {
		getData () {
			this.$root.request('appointmentDetail', {
				id: this.detailId
			}).then((data) => {
				if (data) {
					this.appointment = data.appointment;
					this.deliveryAddress = data.deliveryAddress;
					this.prescription = data.prescription;
					this.patientInfo = data.patientInfo;
					this.doctorInfo = data.doctorInfo;
					let _rp = [];
					if (data.prescription.rp) {
						_rp = JSON.parse(data.prescription.rp);
					}
					this.prescription._rp = _rp;
					this.active = this.activeMap[data.prescription.status];
					switch (data.prescription.dosageForm) {
					case '1':
						data.prescription.dosageForm = '饮片';
						break;
					case '2':
						data.prescription.dosageForm = '颗粒';
						break;
					default:
						break;
					}
					this.getExpressData(this.prescription.id);
				}
			});
		},
		getExpressData (id) {
			this.$root.request('expressDetail', {
				id
			}).then((data) => {
				if (data) {
					this.expressList = data.expressList;
				}
			});
		}
	}
};
</script>

<style lang="less" scoped>
	.steps {
		width: 80%;
	}
	.order-container {
		width: 100%;
		margin-top: 20px;
		.order-status {
			width: 90%;
			min-height: 30px;
			margin: auto;
			border: 1px solid #e8e8e8;
			padding: 8px;
			.order-steps-height {
				padding: 20px;
				min-height: 50px;
				max-height: 300px;
				overflow: auto;
				text-align: center;
				box-sizing: border-box;
			}
		}
	}
	.drugs-list h4 {
		margin: 0;
	}
	.drugs-description {
		display: flex;
    flex-direction: column;
	}
	.small {
		font-size: 14px;
		display: flex;
		line-height: 18px;
	}
	.small>span {
		white-space: nowrap;
	}
	.line {
		width: 100%;
		border-bottom: 1px solid #2d3030;
		display: block;
	}
	.row {
		margin-top: 10px;
		font-size: 15px;
	}
	.normal {
		margin-top: 3px;
		font-size: 16px;
	}
	.bold {
		font-weight: bold;
		font-size: 15px;
	}
	.large {
		font-size: 16px;
	}
	.largex {
		font-size: 18px;
	}
	.express-list {
		.express-item {
			position: relative;
			border-left: 2px solid #e0e0e0;
			padding-left: 10px;
			.express-item-title {
				display: flex;
				align-items: center;
			}
		}
		.express-item .express-item-title > i {
			position: absolute;
			width: 10px;
			height: 10px;
			border-radius: 100%;
			background-color: coral;
			display: block;
			left: -6px;
		}
		.express-item .express-item-title > span {
			font-weight: bold;
		}
		.express-item-child {
			display: flex;
			align-items: center;
			font-size: 13px;
			padding: 10px 0;
			i {
				position: absolute;
				width: 7px;
				height: 7px;
				border-radius: 100%;
				background-color:chocolate;
				display: block;
				left: -5px;
			}
			div {
				line-height: 20px;
				span:last-child {
					margin-left: 14px;
				}
			}
		}
	}

</style>