var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex f-ai-c f-d-c" }, [
    _c(
      "div",
      { staticClass: "steps" },
      [
        _c(
          "el-steps",
          { attrs: { active: _vm.active } },
          [
            _c("el-step", {
              attrs: { title: "待支付", icon: "el-icon-s-order" }
            }),
            _c("el-step", {
              attrs: { title: "付款成功待发货", icon: "el-icon-bank-card" }
            }),
            _c("el-step", {
              attrs: { title: "待收货", icon: "el-icon-alarm-clock" }
            }),
            _c("el-step", { attrs: { title: "完成", icon: "el-icon-check" } })
          ],
          1
        )
      ],
      1
    ),
    _c("div", { staticClass: "order-container" }, [
      _c("div", { staticClass: "order-status flex f-ai-c" }, [
        _c("span", { staticStyle: { "margin-right": "8px" } }, [
          _vm._v(" 订单号：" + _vm._s(_vm.prescription.orderId))
        ]),
        _c("i", { staticClass: "el-icon-s-order" }),
        _c("span", [
          _vm._v(
            "\n\t\t\t\t订单状态：\n\t\t\t\t" +
              _vm._s(
                _vm.prescription.status === 1
                  ? "待支付"
                  : _vm.prescription.status === 5
                  ? "待发货"
                  : _vm.prescription.status === 2
                  ? "待收货"
                  : _vm.prescription.status === 3
                  ? "已完成"
                  : _vm.prescription.status === 4
                  ? "已取消"
                  : _vm.prescription.status === 6
                  ? "已退款"
                  : ""
              ) +
              "\n\t\t\t"
          )
        ])
      ]),
      _c("div", { staticClass: "order-status" }, [
        !_vm.expressList.length
          ? _c("div", { staticClass: "order-steps-height" }, [
              _vm._v("暂无配送信息")
            ])
          : _c(
              "div",
              { staticClass: "express-list" },
              _vm._l(_vm.expressList, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "express-item" },
                  [
                    _c("div", { staticClass: "express-item-title" }, [
                      _c("i"),
                      _c("span", [_vm._v(_vm._s(item.status))])
                    ]),
                    _vm._l(item.list, function(ele, i) {
                      return _c(
                        "div",
                        { key: i, staticClass: "express-item-child" },
                        [
                          _c("i"),
                          _c("div", [
                            _c("span", [_vm._v(_vm._s(ele.time))]),
                            _c("span", [_vm._v(_vm._s(ele.context))])
                          ])
                        ]
                      )
                    })
                  ],
                  2
                )
              }),
              0
            )
      ]),
      _c(
        "div",
        { staticClass: "order-status" },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("h1", [_vm._v("收货人信息")]),
                _vm.deliveryAddress
                  ? _c("div", [
                      _c("div", [
                        _vm._v("收货人：" + _vm._s(_vm.deliveryAddress.name))
                      ]),
                      _c("div", [
                        _vm._v(
                          "地址：" +
                            _vm._s(
                              (_vm.deliveryAddress.provenceName || "") +
                                (_vm.deliveryAddress.cityName || "") +
                                (_vm.deliveryAddress.countyName || "") +
                                (_vm.deliveryAddress.address || "")
                            )
                        )
                      ]),
                      _c("div", [
                        _vm._v(
                          "手机号：" + _vm._s(_vm.deliveryAddress.mobilePhone)
                        )
                      ])
                    ])
                  : _vm._e()
              ]),
              _c(
                "el-col",
                {
                  staticStyle: { margin: "0 10px 0 30px" },
                  attrs: { span: 7 }
                },
                [
                  _c("h1", [_vm._v("配送方式")]),
                  _vm.appointment.logisticsOrderNo
                    ? _c("div", [
                        _c("div", [
                          _vm._v(
                            "送货方式：" + _vm._s(_vm.appointment.logisticsName)
                          )
                        ]),
                        _vm.appointment.logisticsFee
                          ? _c("div", [
                              _vm._v(
                                "运费：" + _vm._s(_vm.appointment.logisticsFee)
                              )
                            ])
                          : _vm._e(),
                        _c("div", [
                          _vm._v(
                            "运单号：" +
                              _vm._s(_vm.appointment.logisticsOrderNo)
                          )
                        ]),
                        _c("div", [_vm._v("物流账户：暂无")])
                      ])
                    : _c("div", [_vm._v("暂无")])
                ]
              ),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("h1", [_vm._v("付款信息")]),
                _c("div", [
                  _vm._v(
                    "付款方式：" +
                      _vm._s(_vm.prescription.type === "WECHAT" ? "微信" : "")
                  )
                ]),
                _c("div", [
                  _vm._v("付款时间：" + _vm._s(_vm.prescription.createdAt))
                ]),
                _c("div", [
                  _vm._v(
                    "药品金额：￥" + _vm._s(_vm.prescription.totalFee || 0)
                  )
                ]),
                _c("div", [
                  _vm._v(
                    "运费金额：￥" + _vm._s(_vm.appointment.logisticsFee || 0)
                  )
                ]),
                _c("div", [
                  _vm._v(
                    "应支付金额：￥" +
                      _vm._s(
                        (_vm.prescription.totalFee || 0) +
                          Number(_vm.appointment.logisticsFee || 0)
                      )
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "order-status" },
        [
          _c(
            "div",
            { staticClass: "drugs-list" },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 11 } }, [
                    _c("h1", [_vm._v("处方信息")])
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "row" },
                [
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c("span", { staticClass: "small" }, [
                      _vm._v("开单科室：" + _vm._s(_vm.doctorInfo.deptName))
                    ])
                  ]),
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c("span", { staticClass: "small" }, [
                      _vm._v("开单医生：" + _vm._s(_vm.doctorInfo.name))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "row bold" },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("span", [
                      _vm._v("姓名：" + _vm._s(_vm.patientInfo.name))
                    ])
                  ]),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("span", [
                      _vm._v(
                        "性别：" +
                          _vm._s(_vm.patientInfo.gender === 1 ? "男" : "女")
                      )
                    ])
                  ]),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("span", [
                      _vm._v("年龄：" + _vm._s(_vm.patientInfo.age) + "岁")
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "row" },
                [
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c("span", { staticClass: "small" }, [
                      _vm._v("处方号：" + _vm._s(_vm.prescription.orderId))
                    ])
                  ]),
                  _c("el-col", { attrs: { span: 9 } }, [
                    _c("span", { staticClass: "small" }, [
                      _vm._v(
                        "处方类型：" +
                          _vm._s(_vm.prescription.dosageForm || "无")
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "row" },
                [
                  _c("el-col", { attrs: { span: 9 } }, [
                    _c("span", { staticClass: "small" }, [
                      _c("span", [_vm._v("诊断：")]),
                      _c("div", [
                        _c("div", [
                          _vm._v("1.中医诊断：" + _vm._s(_vm.prescription.zyzd))
                        ]),
                        _c("div", { staticStyle: { "margin-top": "3px" } }, [
                          _vm._v("2.中医辨证：" + _vm._s(_vm.prescription.zybz))
                        ])
                      ])
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "row" },
                [
                  _c("el-col", { attrs: { span: 9 } }, [
                    _c("span", { staticClass: "small" }, [
                      _c("span", [_vm._v("医嘱：")]),
                      _c("div", [
                        _c("div", [_vm._v(_vm._s(_vm.prescription.yz))])
                      ])
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "row" },
                [
                  _c("el-col", { attrs: { span: 9 } }, [
                    _c("span", { staticClass: "line" })
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "row large largex" },
                [
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c("span", [_vm._v("名称")])
                  ]),
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c("span", [_vm._v("单剂数量")])
                  ])
                ],
                1
              ),
              _vm._l(_vm.prescription._rp, function(item, index) {
                return _c(
                  "el-row",
                  { key: index, staticClass: "row large" },
                  [
                    _c("el-col", { attrs: { span: 5 } }, [
                      _c("span", [_vm._v(_vm._s(item.name))])
                    ]),
                    _c("el-col", { attrs: { span: 4 } }, [
                      _c("span", [
                        _vm._v(_vm._s(item.count) + _vm._s(item.unit))
                      ])
                    ])
                  ],
                  1
                )
              }),
              _c(
                "el-row",
                { staticClass: "row" },
                [
                  _c("el-col", { attrs: { span: 9 } }, [
                    _c("span", { staticClass: "line" })
                  ])
                ],
                1
              )
            ],
            2
          ),
          _c(
            "el-row",
            { staticClass: "row large" },
            [
              _c("el-col", { attrs: { span: 9 } }, [
                _c("div", [
                  _vm._v("共" + _vm._s(_vm.prescription.jiliang) + "剂")
                ])
              ])
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "row normal" },
            [
              _c("el-col", { attrs: { span: 4 } }, [
                _c("span", [_vm._v("用法：" + _vm._s(_vm.prescription.fyff))])
              ]),
              _c("el-col", { attrs: { span: 5 } }, [
                _c("span", [
                  _vm._v(
                    "每日" +
                      _vm._s(_vm.prescription.jlPerDay) +
                      "剂，1剂分" +
                      _vm._s(_vm.prescription.times) +
                      "次服用"
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "row normal" },
            [
              _c("el-col", { attrs: { span: 2 } }, [
                _c("span", [
                  _vm._v("诊费：￥" + _vm._s(_vm.prescription.outpatientCare))
                ])
              ]),
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", [
                  _vm._v(
                    "药品金额：￥" + _vm._s(_vm.prescription.medicinePrice)
                  )
                ])
              ]),
              _c("el-col", { attrs: { span: 2 } }, [
                _c("span", [
                  _vm._v(
                    "加工费用：￥" + _vm._s(_vm.prescription.processingFee)
                  )
                ])
              ]),
              _c("el-col", { attrs: { span: 2 } }, [
                _c("span", [
                  _vm._v("处方管理费：￥" + _vm._s(_vm.prescription.mntCosts))
                ])
              ]),
              _c("el-col", { attrs: { span: 2 } }, [
                _c("span", [
                  _vm._v("运费：￥" + _vm._s(_vm.prescription.logisticsFee))
                ])
              ]),
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", [
                  _vm._v("合计金额：￥" + _vm._s(_vm.prescription.totalFee))
                ])
              ])
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "row normal" },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", [
                  _vm._v("备注：" + _vm._s(_vm.prescription.remarks || "无"))
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }